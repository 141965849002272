.navigation {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.navigation__link {
  margin-right: 16px;
  padding: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  transition: all .3s ease-in-out;
  cursor: pointer;
  text-decoration: none;
  color: #FFFFFF;
  text-align: center;
}

.navigation__link_active {
  font-weight: 500;
}

.navigation__link:last-of-type {
  margin: 0;
}

.navigation__link:hover {
  opacity: 0.7;
}

.navigation-side {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navigation-side__link {
  margin: 0 0 28px 0;
  padding: 0;
  padding-bottom: 4px;
  transition: all .3s ease-in-out;
  text-decoration: none;
  color: #FFFFFF;
  text-align: center;
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  border-bottom: 2px solid rgba(255, 255, 255, 0);
}

.navigation-side__link_active {
  border-bottom: 2px solid rgba(255, 255, 255, 1);
}

.navigation-side__link:last-of-type {
  margin: 0;
}

.navigation-side__link:hover {
  opacity: 0.7;
}