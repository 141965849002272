@keyframes preloader-inside-white {
  0% {
      -webkit-transform: scale(0, 0);
      -moz-transform: scale(0, 0);
      -ms-transform: scale(0, 0);
      -o-transform: scale(0, 0);
      transform: scale(0, 0);
  }
  100% {
      -webkit-transform: scale(1, 1);
      -moz-transform: scale(1, 1);
      -ms-transform: scale(1, 1);
      -o-transform: scale(1, 1);
      transform: scale(1, 1);
  }
}

@keyframes preloader-inside-red {
  0% {
      -webkit-transform: scale(0, 0);
      -moz-transform: scale(0, 0);
      -ms-transform: scale(0, 0);
      -o-transform: scale(0, 0);
      transform: scale(0, 0);
  }
  30% {
      -webkit-transform: scale(0, 0);
      -moz-transform: scale(0, 0);
      -ms-transform: scale(0, 0);
      -o-transform: scale(0, 0);
      transform: scale(0, 0);
  }
  100% {
      -webkit-transform: scale(1, 1);
      -moz-transform: scale(1, 1);
      -ms-transform: scale(1, 1);
      -o-transform: scale(1, 1);
      transform: scale(1, 1);
  }
}

.preloader {
  position: fixed;
  margin: auto;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 20;
  background: #202020;
  visibility: hidden;
  opacity: 0;
  transition: visibility .3s linear, opacity .3s linear;
}

.preloader_opened {
  visibility: visible;
  opacity: 1;
}

.preloader__container {
  width: 100px;
  height: 200px;
  position: relative;
}

.preloader__round {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 0;
  left: 0;
  background: #202020;
  border-radius: 50px;
}

.preloader__round::after,
.preloader__round::before {
  content: '';
  width: 100px;
  height: 100px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50px;
}

.preloader__round::after {
  background: #3DDC84;
  animation: preloader-inside-white 1s ease-in-out infinite;
}

.preloader__round::before {
  z-index: 10;
  background: #202020;
  animation: preloader-inside-red 1s ease-in-out infinite;
}
