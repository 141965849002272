.header-section {
  margin: 0;
  padding: 0 0 23px 0;
  display: flex;
  justify-content: flex-start;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 27px;
  letter-spacing: -0.05em;
  border-bottom: 1px solid #DADADA;
  color: #FFFFFF;
}

@media screen and (max-width: 425px) {
  .header-section {
    font-size: 18px;
    line-height: 22px;
    padding-bottom: 28px;
  }
}