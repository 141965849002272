.portfolio {
  margin: 0;
  padding: 0 70px 125px;
  display: flex;
  flex-direction: column;
  background-color: #202020;
}

.portfolio__title {
  margin: 0 0 50px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 20px;
  color: #8B8B8B;
}

.portfolio__list {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  list-style: none;
}

.portfolio__item {
  margin: 20px 0 0;
  padding: 0 0 20px;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(66, 66, 66, 1);
  transition: opacity .3s ease-in-out;
}

.portfolio__item:first-of-type {
  margin: 0;
}

.portfolio__item:last-of-type {
  border-bottom: none;
}

.portfolio__item:hover {
  opacity: 0.7;
}

.portfolio__link {
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-content: center;
  color: #FFFFFF;
  text-decoration: none;
}

.portfolio__link-title {
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 50px;
  letter-spacing: -0.04em;
  transition: opacity .3s ease-in-out;
}

.portfolio__link-icon {
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 60px;
  letter-spacing: -0.04em;
  transition: opacity .3s ease-in-out;
}

@media screen and (max-width: 768px) {
  .portfolio {
    padding: 0 50px 90px;
  }

  .portfolio__link-title {
    font-size: 28px;
    line-height: 50px;
  }

  .portfolio__link-icon {
    font-size: 28px;
    line-height: 60px;
  }
}

@media screen and (max-width: 560px) {
  .portfolio__title {
    margin-bottom: 40px;
    font-size: 14px;
    line-height: 20px;
  }

  .portfolio__link-title {
    font-size: 18px;
    line-height: 28px;
  }

  .portfolio__link-icon {
    font-size: 18px;
    line-height: 28px;
  }
}

@media screen and (max-width: 425px) {
  .portfolio {
    padding: 0 14px 70px;
  }
}