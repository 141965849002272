.header {
  position: relative;
  margin: 0;
  padding: 0 70px;
  display: flex;
  justify-content: space-between;
  justify-items: center;
  width: 100%;
  min-height: 74px;
  top: 0;
  left: 0;
  right: 0;
  background-color: #073042;
  align-items: center;
  box-sizing: border-box;
}

.header_theme_black {
  background-color: #202020;
}

.header__logo {
  flex-basis: 100px;
  justify-self: flex-start;
}

.header__menu {
  margin: 0;
  display: flex;
}

.header__link {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-decoration: none;
  color: #FFFFFF;
  margin: auto 0;
  transition: opacity .3s ease-in-out;
}

.header__link:hover {
  opacity: 0.7;
}

@media screen and (max-width: 768px) {
  .header {
    padding: 0 30px;
  }
}

@media screen and (max-width: 425px) {
  .header {
    padding: 0 14px;
  }

  .header__link {
    font-size: 10px;
  }
}