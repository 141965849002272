.profile {
  padding: 74px 0 0;
  display: flex;
  flex-direction: column;
}

.profile__title {
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #FFFFFF;
}

.profile__form {
  margin: 123px auto 0;
  display: flex;
  flex-direction: column;
  width: 410px;
}

.profile__form-fieldset {
  margin: 0;
  font-size: 0;
  border: none;
}

.profile__form-container {
  margin: 0;
  display: flex;
  justify-content: space-between;
}

.profile__form-container:first-of-type {
  border-bottom: 1px solid #424242;
}

.profile__form-container:last-of-type {
  margin-top: 16px;
}



.profile__form-caption {
  margin-top: 5px;
  display: flex;
  align-self: flex-start;
  align-items: center;
  min-height: 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #FFFFFF;
}

.profile__form-label {
  position: relative;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.profile__form-input {
  margin: 0;
  padding: 0;
  width: 210px;
  min-height: 17px;
  border: 0;
  box-sizing: none;
  background-color: transparent;
  text-align: right;
  font-size: 13px;
  line-height: 16px;
  font-family: inherit;
  color: #FFFFFF;
}

.profile__form-input:first-of-type {
  margin-bottom: 16px;
}

.profile__form-input:focus {
  border-bottom: 1px solid #4285F4;
  outline: none;
}

.profile__form-input::placeholder {
  font-size: 13px;
  line-height: 16px;
  font-family: inherit;
  color: #8B8B8B;
}

.profile__form-input_type_error {
  border-bottom-color: #424242;
}

.profile__form-error {
  position: absolute;
  margin-top: 5px;
  padding: 0;
  top: 30px;
  left: 0;
  right: 0;
  font-size: 10px;
  line-height: 12px;
  color: #EE3465;
  font-family: inherit;
  opacity: 0;
  transition: .3s;
  text-align: right;
}

.profile__form-error_visible {
  opacity: 1;
}

@media screen and (max-width: 768px) {
  .profile {
    margin: auto;
    padding: 0;
  }

  .profile__form {
    margin-top: 96px;
  }
}

@media screen and (max-width: 450px) {
  .profile {
    margin: 0 auto;
    padding-top: 70px;
    height: 100%;
  }

  .profile__form {
    margin-top: 80px;
    width: 260px;
    height: 100%;
  }
}