.tooltip {
  position: fixed;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition: visibility .3s linear, opacity .3s linear;
  z-index: 10;
}

.tooltip_opened {
  visibility: visible;
  opacity: 1;
}

.tooltip__container {
  position: relative;
  margin: 0 20px;
  padding: 35px;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: #202020;
  max-width: 500px;
  border-radius: 10px;
  box-sizing: border-box;
}

.tooltip__icon {
  margin: 0 0 50px;
  width: 120px;
  height: 120px;
}

.tooltip__title {
  margin: 0;
  width: 80%;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #FFFFFF;
}

@media screen and (max-width: 440px) {
  .tooltip__container {
    padding: 20px;
  }

  .tooltip__title {
    width: 90%;
    font-size: 18px;
    line-height: 22px;
  } 
}