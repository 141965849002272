.page {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  min-width: 320px;
  width: 100%;
  height: 100vh;
  background-color: #202020;
  align-items: center;
}