.promo {
  position: relative;
  padding: 75px 70px 50px;
  margin: 0;
  display: grid;
  grid-template-columns: minmax(auto, 100%);
  row-gap: 36px;
  width: auto;
  max-height: 404px;
  background-color: #073042;
  justify-content: start;
  align-items: start;
  color: #FFFFFF;
}

.promo__title {
  margin: 0;
  padding: 0;
  width: 600px;
  grid-row: 1 / 1;
  grid-column: 1 / 1;
  font-style: normal;
  font-weight: normal;
  font-size: 50px;
  line-height: 58px;
  text-align: left;
  letter-spacing: -0.04em;
}

.promo__text {
  margin: 0;
  max-width: 310px;
  grid-row: 2 / 2;
  grid-column: 1 / 1;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: rgba(255,255,255, 0.5);
}

.promo__image {
  margin: 0;
  width: 310px;
  height: 304px;
  grid-column: 2 / 2;
  grid-row: 1 / 10;
}

@media screen and (max-width: 1024px) {
  .promo {
    padding: 140px 70px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-height: 784px;
  }

  .promo__title {
    max-width: 520px;
    font-size: 40px;
    line-height: 48px;
    text-align: center;
  }

  .promo__text {
    max-width: 260px;
    text-align: center;
  }
}

@media screen and (max-width: 560px) {
  .promo {
    padding: 70px 0;
    min-height: 570px;
  }

  .promo__title {
    max-width: 300px;
    font-size: 31px;
    line-height: 39px;
  }

  .promo__image {
    width: 248px;
    height: 248px;
  }
}