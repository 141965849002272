.footer {
  margin: 0;
  padding: 79px 0 20px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  background-color: #202020;
}

.footer__text {
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #8B8B8B;
}

.footer__stroke {
  margin: 20px 0 0;
  width: calc(100% - 100px);
  height: 1px;
  background-color: #424242;
}

.footer__data {
  display: flex;
  margin: 20px 0 0;
  width: calc(100% - 100px);
  justify-content: space-between;  
  align-items: center;
}

.footer__copyright {
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #FFFFFF
}

.footer__list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer__list-item {
  margin-right: 20px;
}

.footer__list-item:last-of-type {
  margin-right: 0;
}

.footer__link {
  margin: 0;
  text-decoration: none;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #FFFFFF;
  transition: opacity .3s ease-in-out;
}

.footer__link:hover {
  opacity: 0.7;
}

@media screen and (max-width: 425px) {
  .footer {
    padding: 79px 0 10px 0;
  }

  .footer__text {
    font-size: 12px;
    line-height: 15px;
  }

  .footer__data {
    margin-top: 30px;
    flex-direction: column-reverse;
    justify-content: space-between;
  }

  .footer__line {
    margin-top: 21px;
  }

  .footer__copyright {
    font-size: 12px;
    line-height: 15px;
    color: #FFFFFF;
  }

  .footer__list {
    flex-direction: column;
    align-items: center;
  }

  .footer__list-item {
    margin: 0 0 12px;
  }

  .footer__list-item:last-of-type {
    margin-bottom: 30px;
  }
}